import axios from "axios"

import config from "../service/config"

let headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
}

let myAxios = axios.create({
    baseURL: config.API_URL,
    headers: headers,
    // auth: {
    //     username: config.GATSBY_DRUPAL_USERNAME,
    //     password: config.GATSBY_DRUPAL_PASSWORD
    // }
})

myAxios.interceptors.request.use(function(config) {
    return config;
}, function(error) {
    return Promise.reject(error);
});

myAxios.interceptors.response.use(function(response) {
    return response;
}, function(error) {
    return Promise.reject(error)
});

const api = {
    getTags() {
        return new Promise((resolve, reject) => {
            myAxios.get(config.BLOGS.BLOG_TYPES)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getBlogsData(params) {
        return new Promise((resolve, reject) => {
            myAxios.get(config.BLOGS.BLOGS_DATA, {
                params: {...params}
            })
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getMostViewedData(params) {
        return new Promise((resolve, reject) => {
            myAxios.get(config.BLOGS.MOSTVIEWED+'/'+params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getArticles(params) {
        return new Promise((resolve, reject) => {
            myAxios.get(config.BLOGS.ARTICLES, {
                params: {...params}
            })
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    postBlogQuery(payload) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.BLOGS.SUBMIT, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })           
    }
}

export default api
